import { Deferred } from '../helpers/deferred';
export class WatermarkService {
    constructor(userPromise, flowId, apiService, domService, flowVersionId) {
        this.userPromise = userPromise;
        this.flowId = flowId;
        this.apiService = apiService;
        this.domService = domService;
        this.flowVersionId = flowVersionId;
        this.deferred = new Deferred();
        this.hasWatermarkPromise = this.deferred.promise;
    }
    async handle() {
        const user = await this.userPromise;
        const result = await (await this.apiService.getFlowConfig)({
            flowId: this.flowId,
            flowVersionId: this.flowVersionId,
            idToken: await user.getIdToken(),
        });
        if (result.data.showWatermark)
            this.domService.addUiFlag('has-watermark');
        else
            this.domService.removeUiFlag('has-watermark');
        this.deferred.resolve(true);
    }
}
