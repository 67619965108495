import { logger } from '@upflowy/logger';
import '../extra.css';
export async function renderVersionAction({ resourceLoader, asyncRegister, isStandaloneApp, watermarkService, domService, }) {
    try {
        if (isStandaloneApp)
            domService.addUiFlag('is-standalone');
        const versionCssPromise = domService.addStyle({
            url: resourceLoader.toResourceUrl('/index.css'),
            slot: 'version',
        });
        const assets = await resourceLoader.loadJson('/assets.json');
        if (!assets) {
            logger.warn('Assets expected to be present');
        }
        const promises = (assets === null || assets === void 0 ? void 0 : assets.map((asset) => {
            if (asset.type === 'css')
                return domService.addStyle({
                    url: asset.url,
                    slot: 'version-asset',
                });
            if (asset.type === 'js') {
                return domService.addScript(asset.url);
            }
            if (asset.type === 'font') {
                return domService.addFont(asset);
            }
            return Promise.resolve(true);
        })) || [];
        // Ensure all css and assets are loaded
        await Promise.all([...promises, versionCssPromise]);
        asyncRegister.assetsLoaded.resolve(true);
        await watermarkService.handle();
    }
    catch (e) {
        logger.logException(e, { message: 'While rendering version' });
    }
}
