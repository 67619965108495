import { AnalyticsSpanOp, ConsumerAnalyticsService, logger } from '@upflowy/logger';
import { FlowVersionStatus } from '@upflowy/types';
import { addListeners } from './add-listeners';
import { createInterpreter } from './create-interpreter';
import { getExistingConsumerDomain, isV1Flow } from './helper';
import { loadFlowConfig } from './load-flow-config';
import { ApiService } from '../api-service';
import { DomService } from '../dom-service';
import { createConfig } from '../get-config';
import { NotificationService } from '../notification-service';
import { SendEventService } from '../send-event-service';
import { signinUser } from '../signin-user-service';
import { UrlResourceLoader } from '../url-resource-loader';
import { WatermarkService } from '../watermark-service';
// eslint-disable-next-line sonarjs/cognitive-complexity
export async function render(fallbackHandler) {
    var _a, _b, _c, _d, _e;
    const domService = new DomService();
    domService.renderLoader();
    let config = null;
    const url = new URL(window.location.toString());
    try {
        config = await createConfig({ apiService: new ApiService() }, url);
        ConsumerAnalyticsService.setUpflowyTags({
            implementation: config.implementation,
            mode: config.isStandaloneApp ? 'standalone' : 'embedded',
            origin: config.origin !== null ? config.origin : undefined,
        });
        if (config && !config.flowId && !config.customUrl) {
            logger.warn('flowId or customUrl required');
            DomService.pageNotFound();
            return;
        }
    }
    catch (error) {
        logger.warn('Unable to load config.');
    }
    const flowConfig = config ? await loadFlowConfig(config) : null;
    // to make sure run fallback if new flows have app version 1.0.0
    if (isV1Flow(flowConfig) && fallbackHandler) {
        // Maybe v1 flow
        logger.setTag('AppVersion', (_a = flowConfig === null || flowConfig === void 0 ? void 0 : flowConfig.consumerAppVersion) !== null && _a !== void 0 ? _a : '1.0.0');
        fallbackHandler();
        return;
    }
    if (isV1Flow(flowConfig) && !fallbackHandler) {
        logger.warn('Flow not found and no fallback handler');
        DomService.pageNotFound();
        return;
    }
    if (!flowConfig) {
        logger.error('Error loading config');
        DomService.pageNotFound();
        return;
    }
    // when user tries to access flow of other organisation with there domain
    const existingConsumerDomain = getExistingConsumerDomain();
    if (flowConfig.subdomain &&
        existingConsumerDomain &&
        flowConfig.subdomain !== (config === null || config === void 0 ? void 0 : config.subdomain)) {
        logger.error('Flow is not part of this organisation.');
        DomService.pageNotFound();
        return;
    }
    logger.setTag('AppVersion', (_b = flowConfig === null || flowConfig === void 0 ? void 0 : flowConfig.consumerAppVersion) !== null && _b !== void 0 ? _b : '2.0.0');
    ConsumerAnalyticsService.startNewSpan({
        op: AnalyticsSpanOp.FLOW_RENDERING,
        description: 'Render the flow',
    });
    if (config && flowConfig) {
        if (config.implementation !== 'preview' &&
            (!flowConfig.machine ||
                [FlowVersionStatus.DRAFT, FlowVersionStatus.INACTIVE].includes(flowConfig.status))) {
            logger.warn('Flow machine config not found and no fallback handler');
            DomService.resourceNotAvailable();
            return;
        }
        logger.setContext('consumer', {
            flowId: flowConfig.flowId,
            flowVersionId: flowConfig.flowVersionId,
        });
        const parentNotificationService = new NotificationService({
            channel: window.parent,
            scope: {
                origin: (_c = config.origin) !== null && _c !== void 0 ? _c : '',
                containerId: (_d = config.containerId) !== null && _d !== void 0 ? _d : undefined,
            },
            source: config.consumerAppSource,
        });
        const sendEventService = SendEventService({
            flowId: flowConfig.flowId,
            flowVersionId: flowConfig.flowVersionId,
            implementation: config.implementation,
            origin: (_e = config.origin) !== null && _e !== void 0 ? _e : '',
        });
        const userPromise = signinUser();
        const resourceLoader = flowConfig.relativeBasePath
            ? new UrlResourceLoader({
                baseResourceUrl: `${config.baseResourceUrl}${flowConfig.relativeBasePath}`,
            })
            : UrlResourceLoader.create({
                baseResourceUrl: config.configResourceUrl,
                flowId: flowConfig.flowId,
                flowVersionId: flowConfig.flowVersionId,
            });
        if (flowConfig.flowId && flowConfig.flowVersionId) {
            const interpreter = createInterpreter({
                flowConfig,
                userPromise,
                resourceLoader,
                parentNotificationService,
                flowVersionId: flowConfig.flowVersionId,
                sendEventService,
                flowId: flowConfig.flowId,
                config,
                watermarkService: new WatermarkService(userPromise, flowConfig.flowId, new ApiService(), domService, flowConfig.flowVersionId),
                domService,
            });
            addListeners({ isStandaloneApp: config.isStandaloneApp, sessionId: config.initialContext.sessionId }, interpreter);
        }
    }
    else {
        DomService.pageNotFound();
    }
}
